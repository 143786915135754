<template>
    <div ref="providerService">
        <h5 class="md:text-2xl md:mb-10 mb-5 mt-10 text-lg">每日營業時段</h5>
        <HourRangeByWeek v-model="checkBusinessHoursList" />
        <h5 class="md:text-2xl text-lg mt-10 mb-5">活動項目設定</h5>
        <div class="relative w-full border border-gray-100 rounded-lg">
            <div class="overflow-x-auto">
                <el-form ref="form" :model="form" :rules="rules">
                    <table class="myTable">
                        <thead class="text-left">
                            <tr>
                                <th>項目</th>
                                <th>分類狀態</th>
                                <th>定價</th>
                                <th>最低時數</th>
                            </tr>
                        </thead>
                        <tbody v-if="!this.$isEmpty(form.categories) && Object.keys(form.categories).length > 0">
                            <tr v-for="(item, index) in categories" :key="index" class="text-left">
                                <td v-if="form.categories[item.id] !== undefined">{{ item.name }}</td>
                                <td v-if="form.categories[item.id] !== undefined">
                                    <el-form-item class="m-0" :prop="`categories.${item.id}.status`">
                                        <el-select v-model="form.categories[item.id].status" class="w-8/12">
                                            <el-option
                                                v-for="(status, index) in categoryStatus"
                                                :key="index"
                                                :value="status.value"
                                                :label="status.label"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </td>
                                <td v-if="form.categories[item.id] !== undefined">
                                    <el-form-item class="m-0" :prop="`categories.${item.id}.price`">
                                        <el-input
                                            v-model.number="form.categories[item.id].price"
                                            class="w-8/12"
                                            :max="categoryMaxPrice"
                                            :maxlength="6"
                                            placeholder="每小時單價"
                                            @input="handlePrice(item.id, categoryMinPrice, categoryMaxPrice)"
                                            @change="
                                                handlePrice(item.id, $config.CATEGORY_PRICE_MIN_LIMIT, $config.CATEGORY_PRICE_MAX_LIMIT)
                                            "></el-input>
                                    </el-form-item>
                                </td>
                                <td v-if="form.categories[item.id] !== undefined">
                                    <el-form-item class="m-0" :prop="`categories.${item.id}.min_dating_unit`">
                                        <el-select v-model="form.categories[item.id].min_dating_unit" class="w-8/12">
                                            <el-option v-for="item in 24" :key="item" :value="item"> {{ item }}小時 </el-option>
                                        </el-select>
                                    </el-form-item>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </el-form>
            </div>
        </div>
        <h5 class="md:text-2xl text-lg mt-10 mb-5">緩衝時間設定</h5>
        <el-select v-model="datingAfterHours" class="w-[300px]">
            <el-option v-for="item in datingAfterHoursOptions" :key="item.value" :value="item.value" :label="item.label"></el-option>
        </el-select>
    </div>
</template>

<script>
import { mapState } from "vuex";
// 時間選擇組件
import HourRangeByWeek from "@/components/HourRangeByWeek.vue";
export default {
    name: "ProviderService",
    components: {
        HourRangeByWeek,
    },
    props: {
        // 預設是否全選服務時間
        checkBusinessHoursList: {
            type: Object,
            default: () => {
                return { 1: [], 2: [], 3: [], 4: [], 5: [], 6: [], 7: [] };
            },
        },
        // 判斷是否啟用旋轉動畫
        idLoading: {
            type: Boolean,
            default: false,
        },
        // 判斷是否為新增
        isAdd: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapState("providerStore", ["providerData"]),
        datingAfterHoursOptions() {
            const result = [];
            for (let i = 0; i <= 24; i++) {
                result.push({
                    label: `${i} 小時`,
                    value: i,
                });
            }
            return result;
        },
    },
    data() {
        return {
            form: {},
            rules: {
                "categories.1.price": [
                    { required: true, message: "價格為必填", trigger: "blur" },
                    { validator: this.formPriceCheck, trigger: "blur" },
                ],
                "categories.2.price": [
                    { required: true, message: "價格為必填", trigger: "blur" },
                    { validator: this.formPriceCheck, trigger: "blur" },
                ],
                "categories.3.price": [
                    { required: true, message: "價格為必填", trigger: "blur" },
                    { validator: this.formPriceCheck, trigger: "blur" },
                ],
                "categories.4.price": [
                    { required: true, message: "價格為必填", trigger: "blur" },
                    { validator: this.formPriceCheck, trigger: "blur" },
                ],
                "categories.1.min_dating_unit": [
                    {
                        required: true,
                        message: "最低時數為必填",
                        trigger: "change",
                    },
                ],
                "categories.2.min_dating_unit": [
                    {
                        required: true,
                        message: "最低時數為必填",
                        trigger: "change",
                    },
                ],
                "categories.3.min_dating_unit": [
                    {
                        required: true,
                        message: "最低時數為必填",
                        trigger: "change",
                    },
                ],
                "categories.4.min_dating_unit": [
                    {
                        required: true,
                        message: "最低時數為必填",
                        trigger: "change",
                    },
                ],
            },
            // 分類
            categories: [],
            // 分類開啟狀況
            categoryStatus: [
                { label: "開啟", value: 0 },
                { label: "關閉", value: -1 },
                { label: "停用", value: -2 },
            ],
            // 取得供給者服務時間
            getHours: [],
            // 服務商分類價格最高每小時單價額度
            categoryMaxPrice: process.env.VUE_APP_CATEGORY_PRICE_MAX_LIMIT,
            // 服務商分類價格最小每小時單價額度
            categoryMinPrice: process.env.VUE_APP_CATEGORY_PRICE_MIN_LIMIT,
            // 緩衝時間設定
            datingAfterHours: 12,
        };
    },
    methods: {
        /**
         * 服務分類每小時單價最高最小額度限制
         * @param { type Strin or Number(字串或數字) } key 服務項目分類 key
         * @param { type Number(數字) } minPrice 最小額度
         * @param { type Number(數字) } maxPrice
         */
        handlePrice(key, minPrice, maxPrice) {
            const price = this.form.categories[key].price;
            if (price > maxPrice) {
                this.$set(this.form.categories[key], "price", maxPrice);
                return;
            }
            this.$set(this.form.categories[key], "price", price);
        },
        // 判斷價格是否小於 1000
        formPriceCheck(rule, value, callback) {
            // 服務分類每小時額度不可小於 1000
            if (value < process.env.VUE_APP_CATEGORY_PRICE_MIN_LIMIT) {
                return callback(new Error(`每小時單價需大於${process.env.VUE_APP_CATEGORY_PRICE_MIN_LIMIT}`));
            }
            // 判斷是否超過 九十九萬九千九百九十九
            if (value > this.categoryMaxPrice) {
                return callback(new Error("金額不得超過六位數"));
            }
            return callback();
        },
        // 設定表單預設值
        setDefault() {
            this.$set(this.form, "categories", {});
            // 判斷 是否有設定過分類資料
            if (!this.$isEmpty(this.providerData) && !this.isAdd) {
                if (!this.$isEmpty(this.providerData.categories)) {
                    this.providerData.categories.forEach((item) => {
                        this.$set(this.form["categories"], item.id, {
                            status: item.pivot.status,
                            min_dating_unit: item.pivot.min_dating_unit,
                            price: item.pivot.price,
                        });
                    });
                }
                // 緩衝時間設定預設為12小時
                this.datingAfterHours = this.providerData.setting.datingAfterHours >= 0 ? this.providerData.setting.datingAfterHours : 12;
            } else {
                this.categories.forEach((item) => {
                    this.$set(this.form["categories"], item.id, {
                        status: 0,
                        min_dating_unit: 1,
                        price: 1000,
                    });
                });
            }
        },
        // 判斷是否有分類都是關閉或未停用的情況
        checkCategoriesAllClose() {
            let count = 0;
            Object.keys(this.form.categories).forEach((objKey) => {
                if (this.form.categories[objKey].status !== 0) {
                    count++;
                }
            });
            // 當加總起來等於4時代表所有分類沒有一個為開啟狀態
            if (count === 4) {
                return false;
            } else {
                return true;
            }
        },
        /**
         * 表單發送
         * @param { type String(字串) } formName 傳送表單名稱
         * @example ref="form"
         */
        async onSubmit(formName) {
            // 緩衝時間設定
            this.$emit("updateSetting", "datingAfterHours", this.datingAfterHours);
            if (!this.isAdd) {
                // 判斷服務商分類是否全部關閉
                const isAllClose = this.checkCategoriesAllClose();
                if (!isAllClose) {
                    // categoris 表單資料驗證失敗時回傳給父組件
                    this.$message({
                        type: "error",
                        message: "至少開啟一個服務項目",
                    });
                    return this.$emit("update:serviceFormValidate", false);
                }
            }
            try {
                // 等待表單驗證是否成功 try catch 會自動判斷是 true 或 false 因次不用寫 if 判斷
                await this.$refs[formName].validate();
                // categroies 表單資料回傳給父組件
                this.$emit("update:categoriesFormData", this.form.categories);
                // categories 表單驗證成功時回傳給父組件
                this.$emit("update:serviceFormValidate", true);
                return true;
            } catch (err) {
                console.log(err);
                // categoris 表單資料驗證失敗時回傳給父組件
                this.$emit("update:serviceFormValidate", false);
                this.$message({
                    type: "error",
                    message: "分類表單驗證失敗",
                });
                return false;
            }
        },
        /**
         * 取得分類列表a api
         */
        async getCategoriesList() {
            try {
                const { data } = await this.$api.GetCategoriesListApi();
                this.categories = data.categories;
                // 設定分類表單資料
                this.setDefault();
            } catch (err) {
                this.$message({
                    type: "error",
                    message: "取得分類列表失敗",
                });
            }
        },
    },
    watch: {
        providerData() {
            this.setDefault();
        },
    },
    async created() {
        await this.getCategoriesList();
        await this.setDefault();
        this.$nextTick(() => {
            this.$refs["form"].clearValidate();
        });
    },
    // 因為快取關西所以還要再執行一次
    async activated() {
        await this.getCategoriesList();
        await this.setDefault();
        this.$nextTick(() => {
            this.$refs["form"].clearValidate();
        });
    },
};
</script>
