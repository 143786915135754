<template>
    <div class="checkboxs-content">
        <div class="week-titles">
            <div class="left-row"></div>
            <!-- check weeks -->
            <div class="right-row">
                <el-checkbox-group v-model="checkWeeks" class="flex w-[100%]">
                    <div v-for="(i, idx) in daysTitles" :key="idx" class="day-title">
                        <div class="title">
                            {{ i }}
                        </div>
                        <div class="checkbox">
                            <el-checkbox class="block" :label="`${idx + 1}`" @change="checkDay($event, idx + 1)">&nbsp;</el-checkbox>
                        </div>
                    </div>
                </el-checkbox-group>
            </div>
        </div>
        <div class="week-box">
            <!-- check times -->
            <div class="left-row">
                <el-checkbox-group v-model="checkHours">
                    <div v-for="(i, idx) in hours" :key="idx" class="time-title">
                        <div class="title">
                            {{ i }}
                        </div>
                        <div class="checkbox">
                            <el-checkbox class="block" :label="`${idx}`" @change="checkTime($event, idx)">&nbsp;</el-checkbox>
                        </div>
                    </div>
                </el-checkbox-group>
            </div>
            <!-- check single time -->
            <div class="right-row" style="padding: 0 8px">
                <div v-for="i in 7" :key="i" class="week-checkbox">
                    <el-checkbox-group v-model="checkList[i]" style="width: 100%">
                        <div v-for="(j, idx) in hours" :key="idx" class="checkbox">
                            <el-checkbox class="block" :label="idx"> &nbsp;</el-checkbox>
                        </div>
                    </el-checkbox-group>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "HourRangeByWeek",
    props: {
        value: {
            type: Object,
            default: () => {
                return { 1: [], 2: [], 3: [], 4: [], 5: [], 6: [], 7: [] };
            },
            require: true,
        },
    },
    computed: {
        checkList: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit("input", val);
            },
        },
    },
    data() {
        return {
            // 畫面輸入匡勾選
            checkWeeks: [],
            checkHours: [],
            daysTitles: ["一", "二", "三", "四", "五", "六", "日"],
            hours: [
                "00",
                "01",
                "02",
                "03",
                "04",
                "05",
                "06",
                "07",
                "08",
                "09",
                "10",
                "11",
                "12",
                "13",
                "14",
                "15",
                "16",
                "17",
                "18",
                "19",
                "20",
                "21",
                "22",
                "23",
            ],
        };
    },
    methods: {
        // 星期勾選控制
        checkDay($event, dayNumber) {
            // 全勾
            if ($event) {
                this.checkList[dayNumber] = [
                    0,
                    ...Array(23)
                        .fill()
                        .map((value, index) => index + 1),
                ];
            } else {
                //清空勾選
                this.checkList[dayNumber] = [];
            }
        },
        // 時間勾選控制
        checkTime($event, timeNumber) {
            // 確認星期勾選
            for (const key in this.checkList) {
                // 全勾
                if ($event && !this.checkList[key].includes(timeNumber)) {
                    this.checkList[key].push(timeNumber);
                }
                //清空勾選
                if (!$event) {
                    this.checkList[key] = this.checkList[key].filter((i) => i !== timeNumber);
                }
            }
        },
    },
    watch: {
        checkList: {
            deep: true,
            handler(val) {
                // 確認星期勾選 , set checkWeeks data
                for (const key in val) {
                    // 長度為24, 設定此星期為勾 , 反之為不勾
                    if (val[key].length === 24) {
                        if (!this.checkWeeks.includes(key)) {
                            this.checkWeeks.push(key);
                        }
                    } else {
                        this.checkWeeks = this.checkWeeks.filter((i) => i !== key);
                    }
                }
                // 確認時間勾選 , set checkHours data
                this.checkHours = [];
                for (let i = 0; i <= 23; i++) {
                    const hasChecked =
                        val[1].includes(i) &&
                        val[2].includes(i) &&
                        val[3].includes(i) &&
                        val[4].includes(i) &&
                        val[5].includes(i) &&
                        val[6].includes(i) &&
                        val[7].includes(i);
                    if (hasChecked) {
                        this.checkHours.push(`${i}`);
                    }
                }
            },
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep .el-checkbox__input.is-checked .el-checkbox__inner {
    background-color: black;
    border-color: black;
}
.checkboxs-content {
    max-width: 600px;
    min-width: 380px;
    width: 60%;
    border: 1px solid #ccc;
    .week-titles {
        width: 100%;
        display: flex;
    }
    .week-box {
        width: 100%;
        display: flex;
    }
    .left-row {
        width: 20%;
        border: 1px solid #ccc;
        .time-title {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 5px;
            height: 31px;
            &:nth-child(6),
            &:nth-child(12),
            &:nth-child(18) {
                border-bottom: 1px solid #ccc;
            }
            .title {
                width: 20px;
                font-size: 18px;
                color: black;
                height: 20px;
                line-height: 20px;
            }
        }
    }
    .right-row {
        flex-grow: 1;
        display: flex;
        border: 1px solid #ccc;
        padding: 8px;
        .day-title {
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            .title {
                font-size: 18px;
            }
        }
        .week-checkbox {
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            .checkbox {
                height: 31px;
                &:nth-child(6),
                &:nth-child(12),
                &:nth-child(18) {
                    border-bottom: 1px solid #ccc;
                }
            }
        }
    }
    .checkbox {
        padding: 5px 0 5px 13px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
</style>
