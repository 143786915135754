<template>
    <el-form ref="form" :model="form" :rules="rules">
        <div class="grid grid-cols-12 gap-4 px-5 relative">
            <div class="col-span-3 px-2">
                <AvatarUpload
                    ref="avatarUpload"
                    v-loading="loading"
                    :showTip="false"
                    :defaultImg="defaultAvatar"
                    :useDialog="true"
                    @onCropped="avatarCroppedData" />
                <el-form-item class="w-full mt-10">
                    <label class="text-gray-400 mb-0 block">實名驗證</label>
                    <p class="text-base -mt-2">{{ authLevel | statusText }}</p>
                </el-form-item>
                <el-form-item v-if="isUpdate" class="w-full" prop="status">
                    <label class="text-gray-400 mb-0 block">帳號狀態</label>
                    <el-select v-model="form.status" class="w-full" :disabled="providerData.status === -2" placeholder="請選擇狀態">
                        <el-option v-for="(option, index) in statusOptions" :key="index" :label="option.label" :value="option.value"> </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item class="w-full" prop="role">
                    <label class="text-gray-400 mb-0 block">上架狀態</label>
                    <el-select v-model="form.role" class="w-full" placeholder="請選擇狀態">
                        <el-option v-for="(option, index) in alreadyOptions" :key="index" :label="option.label" :value="option.value"> </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item class="w-full" prop="withdraw">
                    <label class="text-gray-400 mb-0 block">立即提領</label>
                    <el-select v-model="form.withdraw" class="w-full" placeholder="請選擇狀態">
                        <el-option v-for="(option, index) in withdrawOptions" :key="index" :label="option.label" :value="option.value"> </el-option>
                    </el-select>
                </el-form-item>
            </div>
            <div class="col-span-9 px-2">
                <div class="grid grid-cols-2 md:gap-4">
                    <el-form-item class="w-full md:col-span-1 col-span-2 mb-3" prop="phone">
                        <label class="text-gray-400 mb-0 leading-none">
                            手機號碼
                            <el-input v-model="form.phone" class="w-full"></el-input>
                        </label>
                    </el-form-item>
                    <el-form-item class="w-full md:col-span-1 col-span-2 mb-3" prop="password">
                        <label class="text-gray-400 mb-0 leading-none">
                            密碼
                            <div class="relative">
                                <el-input v-model="form.password" class="w-full" :type="showPassword ? 'text' : 'password'"></el-input>
                                <!-- 眼睛開關 用來顯示密碼使用 -->
                                <span class="absolute right-2 top-1 cursor-pointer text-xl" @click="showPassword = !showPassword">
                                    <i v-if="showPassword" class="icofont-eye-alt"></i>
                                    <i v-if="!showPassword" class="icofont-eye-blocked"></i>
                                </span>
                            </div>
                        </label>
                    </el-form-item>
                </div>
                <div class="grid grid-cols-2 md:gap-4">
                    <el-form-item class="w-full md:col-span-1 col-span-2 mb-3" prop="name">
                        <label class="text-gray-400 mb-0 leading-none">
                            暱稱
                            <el-input v-model="form.name" class="w-full"></el-input>
                        </label>
                    </el-form-item>
                    <el-form-item class="w-full md:col-span-1 col-span-2 mb-3" prop="real_name">
                        <label class="text-gray-400 mb-0 leading-none">
                            真實姓名
                            <el-input v-model="form.real_name" class="w-full"></el-input>
                        </label>
                    </el-form-item>
                </div>
                <div class="grid grid-cols-2 md:gap-4">
                    <el-form-item class="w-full md:col-span-1 col-span-2 mb-3" prop="gender">
                        <label class="text-gray-400 mb-0 leading-none">
                            性別
                            <el-select v-model="form.gender" class="w-full" placeholder="請選擇性別">
                                <el-option v-for="(option, index) in genderOptions" :key="index" :label="option.label" :value="option.value">
                                </el-option>
                            </el-select>
                        </label>
                    </el-form-item>
                    <el-form-item class="w-full md:col-span-1 col-span-2 mb-3" prop="birthday">
                        <label class="text-gray-400 mb-0 leading-none">
                            生日
                            <el-date-picker
                                v-model="form.birthday"
                                class="w-full"
                                type="date"
                                :default-value="defaultValue()"
                                :picker-options="pickerOptions"
                                placeholder="請選擇日期">
                            </el-date-picker>
                        </label>
                    </el-form-item>
                </div>
                <div class="grid grid-cols-2 md:gap-4">
                    <div class="w-full md:col-span-1 col-span-2 mb-3">
                        <el-form-item prop="email">
                            <label class="text-gray-400 mb-0 leading-none">
                                Email
                                <el-input v-model="form.email" placeholder="請輸入電子郵件" class="w-full" />
                            </label>
                        </el-form-item>
                        <el-checkbox-group v-model="form.marketing_notification">
                            <el-checkbox class="text-gray-300 leading-none" :label="0"
                                >我不想收到&nbsp;City&nbsp;Banana&nbsp;相關的行銷資訊</el-checkbox
                            >
                        </el-checkbox-group>
                    </div>
                    <el-form-item prop="government_cert">
                        <label class="text-gray-400 mb-0 block">身份證</label>
                        <div class="flex">
                            <el-select v-model="form.government_cert" class="rounded-tr-none rounded-none max-w-[120px]" placeholder="證件型態">
                                <el-option v-for="(item, index) in certOptions" :key="index" :label="item.label" :value="item.value"> </el-option>
                            </el-select>
                            <el-form-item class="flex-1" prop="government_id">
                                <el-input v-model="form.government_id" placeholder="請輸入身份證" class="w-full" />
                            </el-form-item>
                        </div>
                    </el-form-item>
                </div>
                <div class="flex items-end mb-3 mt-2">
                    <el-form-item prop="district">
                        <label class="text-gray-400 leading-none">
                            <span class="block mb-3">所在地區</span>
                            <el-select v-model="form.district" class="max-w-[150px] rounded-tr-none rounded-none" placeholder="請選擇地區">
                                <el-option v-for="(item, key) in areaOptions" :key="key" :label="item.name" :value="key"> </el-option>
                            </el-select>
                        </label>
                    </el-form-item>
                    <el-form-item class="flex-1" prop="address">
                        <el-input v-model="form.address"> </el-input>
                    </el-form-item>
                </div>
                <span class="block text-gray-400 text-sm mb-3 leading-none">客服註記</span>
                <slot name="provider-remarks"></slot>
                <el-form-item v-permission="['update']" class="w-full">
                    <div v-loading="sendNoteloading" class="flex items-end">
                        <label class="flex-1">
                            <el-input v-model="note" class="w-full" placeholder="請輸入註記內容"> </el-input>
                        </label>
                        <btn
                            color="border-red-500 border text-red-500"
                            size="md:text-sm text-xs px-2 py-2 rounded max-w-[150px]"
                            customClass="ml-2"
                            @onClick="sendNote(note)">
                            <!-- <i class="el-icon-plus text-lg"></i> -->
                            送出註記
                        </btn>
                        <!-- <button
                            class="border-orange-600 border px-5 h-[40px] ml-2 rounded-lg text-orange-600 transition duration-200 hover:bg-orange-500 hover:text-white md:text-sm text-xs"
                            @click.prevent="sendNote(note)">
                            <i class="el-icon-plus text-lg"></i>
                            送出註記
                        </button> -->
                    </div>
                </el-form-item>
            </div>
            <!-- 裁切時使用彈窗方式 -->
            <!-- <el-dialog :visible.sync="showDialog"
                   :close-on-click-modal="true"
                   :modal-append-to-body="true"
                   :close-on-press-escape="true"
                   @close="closeDialog">
        </el-dialog> -->
        </div>
    </el-form>
</template>

<script>
import { mapState, mapMutations } from "vuex";
// 上傳頭像組件
import AvatarUpload from "../../../../../components/AvatarUpload.vue";
// 使用語系檔取得地區資料
import { areas } from "../../../../../langs/tw.json";
/**
 * formNickNameCheck: 驗證暱稱是否有超過 10個字 以及 不可輸入 emoji 符號
 * formCheckUserAge: 判斷是否18歲
 */
import { formCheckUserAge, formNickNameCheck } from "../../../../../service/formRules";
// 判斷是否小於 18 歲日期 disabled 跟 未來時間 disabled 以及預設日期為 18歲當年月份
import datepickerMixin from "@/service/datepickerMixin";
// 導入 firebase 更新聊天室全部聊天對象資料方法
import firebaseChatMixin from "@/service/firebaseChatMixin";
// 導入 實名驗證對應狀態名詞
import AuthenticationCheck from "@/service/authenticationCheckMixin";

// 導入自定義 按鈕
import Btn from "@/components/Button.vue";

export default {
    mixins: [datepickerMixin, firebaseChatMixin, AuthenticationCheck],
    components: {
        AvatarUpload,
        Btn,
    },
    props: {
        isLoading: {
            type: Boolean,
            default: false,
        },
        // 判斷是否為更新
        isUpdate: {
            type: Boolean,
            default: false,
        },
        // 判斷是否為 provider
        isProvider: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapState("providerStore", ["providerData"]),
    },
    data() {
        return {
            form: {
                marketing_notification: [],
            },
            rules: {
                phone: [
                    {
                        required: true,
                        message: "手機為必填",
                        trigger: "blur",
                    },
                    {
                        pattern: /8869[0-9]{8}$/,
                        message: `手機格式錯誤`,
                        trigger: "blur",
                    },
                ],
                real_name: [
                    {
                        required: true,
                        message: "真實姓名為必填",
                        trigger: "blur",
                    },
                    {
                        min: 2,
                        message: "真實姓名需兩字以上",
                        trigger: "blur",
                    },
                ],
                name: [
                    {
                        required: true,
                        message: "暱稱為必填",
                        trigger: "blur",
                    },
                    {
                        required: true,
                        validator: this.formNickNameCheck,
                        trigger: "blur",
                    },
                ],
                birthday: [
                    {
                        required: true,
                        message: "生日為必填",
                        trigger: "change",
                    },
                    {
                        required: true,
                        validator: this.formCheckUserAge,
                        trigger: "change",
                    },
                ],
                gender: [
                    {
                        required: true,
                        message: "性別為必填",
                        trigger: ["change", "blur"],
                    },
                ],
                address: [
                    {
                        required: true,
                        message: "地址為必填",
                        trigger: "blur",
                    },
                ],
                district: [
                    {
                        required: true,
                        message: "所在區域為必填",
                        trigger: ["blur"],
                    },
                ],
                email: [
                    { required: true, message: "信箱為必填", trigger: "blur" },
                    {
                        type: "email",
                        message: "錯誤的電子郵件格式",
                        trigger: ["blur", "change"],
                    },
                    {
                        required: true,
                        validator: this.formEmailCheck,
                        trigger: "blur",
                    },
                ],
                government_id: [
                    {
                        required: true,
                        message: "身份證字號為必填",
                        trigger: "blur",
                    },
                    // 身份證字號驗證
                    // { required: true, pattern: /^[A-Z][12][0-9]{8}$/, message: "請輸入正確的身份證字號", trigger: "blur" }
                ],
                government_cert: [
                    {
                        required: true,
                        message: "證件型態為必填",
                        trigger: "blur",
                    },
                    // 身份證字號驗證
                    // { required: true, pattern: /^[A-Z][12][0-9]{8}$/, message: "請輸入正確的身份證字號", trigger: "blur" }
                ],
                role: [
                    {
                        required: true,
                        message: "請修改上架狀態",
                        trigger: "change",
                    },
                    // { required: true, pattern: /^[1-2]{1}$/, message: "請修改上架狀態", trigger: "blur" }
                ],
            },
            // 區域選擇,
            areaOptions: areas,
            // 選擇性別資料
            genderOptions: [
                {
                    label: "男",
                    value: "male",
                },
                {
                    label: "女",
                    value: "female",
                },
            ],
            // 身份認證類別選擇
            certOptions: [
                {
                    label: "身份證",
                    value: 0,
                },
                {
                    label: "護照",
                    value: 1,
                },
                {
                    label: "居留證",
                    value: 2,
                },
            ],
            // 上架狀態選擇
            alreadyOptions: [
                { label: "服務商上架", value: 1 },
                { label: "服務商下架", value: 2 },
                // { label: "請選擇上架狀態", value: 0 }
            ],
            // 立即提領狀態選擇
            withdrawOptions: [
                { label: "開啟", value: true },
                { label: "關閉", value: false },
                // { label: "請選擇上架狀態", value: 0 }
            ],
            // 帳號狀態
            statusOptions: [
                { label: "使用中", value: 0 },
                // { label: "使用中", value: 2 },
                { label: "停權", value: -1 },
                { label: "永久停權", value: -2 },
            ],
            // 判斷 input 輸入框使用 text 或者 password模式
            showPassword: false,
            // 判斷是否啟用旋轉動畫
            loading: false,
            // 預設大頭照
            defaultAvatar: null,
            // 客服註記
            note: null,
            // 發送客服註記旋轉動畫
            sendNoteloading: false,
            // 判斷是否顯示 永久停權的警示彈窗
            showDialog: false,
        };
    },
    methods: {
        ...mapMutations("providerStore", ["setProviderData"]),
        // 判斷email 是否在特定網域
        formEmailCheck(rule, value, callback) {
            // 需過濾的網址
            const filterDomains = ["example"];
            // 取得輸入匡eamil值 並且取出 @ 之後的網址 接這在取出 . 之前的字串
            const emailDomain = value.split("@")[1].split(".")[0];
            if (filterDomains.includes(emailDomain)) {
                return callback(new Error("email 格式錯誤"));
            }
            return callback();
        },
        // 暱稱驗證
        async formNickNameCheck(rule, value, callback) {
            const { success, message } = await formNickNameCheck(value);
            if (success) {
                return callback();
            }
            return callback(new Error(message));
        },
        // 判斷是否18歲
        async formCheckUserAge(rule, value, callback) {
            const { success, message } = await formCheckUserAge(value);
            if (success) {
                return callback();
            }
            return callback(new Error(message));
        },
        /**
         * 裁切成功後回傳財切檔案
         * @param { type Blob(檔案) } val 裁切圖片檔
         */
        async avatarCroppedData(val) {
            this.loading = true;
            try {
                const result = new FormData();
                result.append("image", val);
                const { data } = await this.$api.UploadAvatarApi(this.$route.params.id, result);
                // // 重新取得使用者資料
                // const userData = await this.getData(true);
                // // 更新firebase 聊天對象中屬於目前修改對象的資料
                // await this.updateFirebaseChatRoomUserData(userData.banana_id, userData);
                // 設定上傳後的圖片網址到 頭像組件中
                this.$refs.avatarUpload.setCropped(data.avatar);
                this.defaultAvatar = data.avatar;
                this.loading = false;
                this.$message({
                    type: "success",
                    message: "上傳頭像成功",
                });
                this.$emit("updateAvatar", data.avatar);
            } catch (err) {
                this.loading = false;
                // 上傳失敗時要清除上傳頭像組件資料
                this.$refs.avatarUpload.handleRemove();
                this.$message({
                    type: "error",
                    message: "上傳頭像失敗",
                });
            }
        },
        /**
         * 取得當前服務商資料
         * @param { type Boolean(布林值) } isUploadAvatar 判斷是上傳頭像時 不更新服務商store資料
         */
        async getData(isUploadAvatar = false) {
            try {
                // 取得服務商資料
                const { data } = await this.$api.GetUserDataApi(this.$route.params.id);
                this.loading = false;
                // 不是上傳頭像時設定服務商資料 (因為 watch機制影響 上傳頭像會清空服務商詳細資料表單中值)
                if (!isUploadAvatar) {
                    // 設定 服務商 資料
                    this.setProviderData(data);
                }
                return data;
            } catch (err) {
                this.loading = false;
                this.$message({
                    type: "error",
                    message: "取得使用者資料失敗",
                });
            }
        },
        // 設定表單預設值
        setDefault(providerData) {
            if (providerData === undefined) {
                return;
            }
            // 設定預設大頭照
            this.defaultAvatar = providerData.avatar ?? "/img/avatar/avatar-icon.svg";
            this.form = {
                status: providerData.status === 2 ? 0 : providerData.status,
                role: !this.isUpdate && providerData.role === 0 ? undefined : providerData.role,
                phone: providerData.phone,
                name: providerData.name,
                real_name: providerData.real_name,
                gender: providerData.gender,
                birthday: providerData.birthday,
                email: providerData.email,
                government_id: providerData.government_id,
                government_cert: providerData.government_cert ?? null,
                district: providerData.district,
                address: providerData.address,
                setting: providerData.setting,
                withdraw: !this.$isEmpty(providerData.setting) ? providerData.setting.disabledWithdraw : true,
            };
            this.authLevel = providerData.status;
            this.$set(this.form, "marketing_notification", [providerData.marketing_notification]);
        },
        /**
         * 表單發送
         * @param { type String(字串) } formName 傳送表單名稱
         * @example ref="form"
         */
        async onSubmit(formName) {
            try {
                // 等待表單驗證是否成功 try catch 會自動判斷是 true 或 false 因次不用寫 if 判斷
                await this.$refs[formName].validate();
                // 判斷沒有有上傳大頭照
                if (this.defaultAvatar === "/img/avatar/avatar-icon.svg") {
                    // profile 表單驗證失敗時回傳給父組件
                    this.$emit("update:profileFormValidate", false);
                    return this.$message({
                        type: "error",
                        message: "請上傳大頭照",
                    });
                }
                // 判斷供給者身份 因為預設為 0 是會員 需要做判斷 傳送值如果為 0 時 不可新增
                if (this.form.role === undefined && !this.isUpdate && this.isProvider) {
                    // profile 表單驗證失敗時回傳給父組件
                    this.$emit("update:profileFormValidate", false);
                    return this.$message({
                        type: "error",
                        message: "請選擇上架狀態",
                    });
                }
                // 當選擇永久停權狀態時要執行
                if (this.form.status === -2 && this.providerData.status !== -2) {
                    // 判斷是否確認更改使用者狀態為永久停權
                    const isSuspension = await this.isSuspension();
                    // 當選擇取消永久停權時 不在往下執行更新
                    if (!isSuspension) {
                        // profile 表單驗證失敗時回傳給父組件
                        this.$emit("update:profileFormValidate", false);
                        return;
                    }
                }
                /**
                 * 判斷目前是否有勾選不想要收到網頁廣告信 因為有勾選值為 false 特列 因此需要特別處理
                 * 原本值為陣列 取消勾選後 裡面值為空陣列 因此判斷空陣列傳送值 為 true
                 * 有值時傳送值為 false
                 */
                if (this.form.marketing_notification.length <= 0) {
                    this.form.marketing_notification = 1;
                } else {
                    this.form.marketing_notification = 0;
                }
                // 判斷服務商是否可立即提領設定值
                this.form.setting = { ...this.form.setting, disabledWithdraw: this.form.withdraw };
                // 轉換生日日期格式
                this.form.birthday = this.$moment(this.form.birthday).format("YYYY-MM-DD");
                // profile 表單資料回傳給父組件
                this.$emit("update:profileFormData", this.form);
                // profile 表單驗證成功時回傳給父組件
                this.$emit("update:profileFormValidate", true);
            } catch (err) {
                // profile 表單驗證失敗時回傳給父組件
                this.$emit("update:profileFormValidate", false);
                this.$message({
                    type: "error",
                    message: "表單驗證失敗",
                });
            }
        },
        /**
         * 發送客服註記
         * @param { type String (字串) } val 註記內容
         */
        async sendNote(val) {
            this.sendNoteloading = true;
            try {
                await this.$api.CreateNotesApi(this.$route.params.id, val);
                this.sendNoteloading = false;
                this.note = null;
                this.$message({
                    type: "success",
                    message: "新增客服註記成功",
                });
                // 新增完後 回傳給父組件 取得最新列表
                this.$emit("updateNotesList", true);
            } catch (err) {
                this.sendNoteloading = false;
                this.$message({
                    type: "error",
                    message: "新增客服註記失敗",
                });
            }
        },
        // 判斷選擇永久停權時
        async isSuspension() {
            try {
                const opts = {
                    title: "確認是否永久停權此用戶",
                    message: "要執行此操作，請在下面的表格中輸入 CONFIRM。",
                };
                await this.$pop.popConfirmType(opts);
                return true;
            } catch (err) {
                this.$message({
                    type: "error",
                    message: "取消此操作",
                });
                return false;
            }
        },
    },
    watch: {
        providerData(val) {
            this.setDefault(val);
        },
    },
    created() {
        this.$nextTick(() => {
            this.$refs["form"].clearValidate();
        });
        // 設定表單資料內容
        this.setDefault(this.providerData);
        if (this.isUpdate) {
            this.alreadyOptions = [
                { label: "回到會員身份", value: 0 },
                { label: "服務商上架", value: 1 },
                { label: "服務商下架", value: 2 },
            ];
        }
    },
    activated() {
        this.$nextTick(() => {
            this.$refs["form"].clearValidate();
        });
        // 設定表單資料內容
        this.setDefault(this.providerData);
    },
};
</script>

<style lang="scss" scoped>
::v-deep .el-date-editor {
    @apply w-full;
}
</style>
