<template>
    <div class="overflow-x-auto whitespace-nowrap relative py-5">
        <!-- 封面照上傳組件 -->
        <CoverUpload
            ref="coverPhotoUpload"
            v-loading="coverLoading"
            class="mx-2 inline-block align-middle"
            :showTip="false"
            :useUploadString="true"
            :useDialog="true"
            :useCircleImg="false"
            :showCameraIcon="false"
            :coverPhoto="coverPhoto"
            @onCropped="coverPhotodData"
            @onRemovePhoto="deleteCoverPhoto" />
        <div v-for="(image, index) in imageList" :key="index" class="inline-block">
            <div v-loading="loading" class="inline-block align-middle">
                <div class="mx-2 relative">
                    <img class="w-32 h-32 rounded-lg" :src="image.url" alt="" />
                    <div
                        class="cursor-pointer bg-opacity-50 bg-black w-6 h-6 flex items-center justify-center text-white absolute -top-2 -right-2 md:text-sm rounded-full text-xs"
                        @click="deleteOtherPhoto(index, image.id)">
                        <i class="icofont-ui-close text-[10px]"></i>
                    </div>
                </div>
            </div>
        </div>
        <!-- 多圖上傳組件 -->
        <MutipleImageUpload
            v-if="imageList.length < 9"
            ref="mutipleUpload"
            v-loading="loading"
            class="inline-block align-middle"
            @onCropped="otherPhotoData" />
    </div>
</template>

<script>
import { mapState } from "vuex";
// 上傳頭像組件
import CoverUpload from "../../../../../../components/AvatarUpload.vue";
// 多圖上傳組件
import MutipleImageUpload from "../../../../../../components/MultipleImageUpload.vue";
export default {
    name: "ProviderPhotos",
    components: {
        CoverUpload,
        MutipleImageUpload,
    },
    computed: {
        ...mapState("userStore", ["user"]),
        ...mapState("providerStore", ["providerData"]),
    },
    data() {
        return {
            // 裁切後圖片
            imageList: [],
            // 封面照圖片
            coverPhoto: null,
            // 旋轉動畫
            coverLoading: false,
            // 旋轉動畫
            loading: false,
        };
    },
    methods: {
        /**
         * 裁切成功後回傳財切檔案
         * @param { type Blob(檔案) } file 裁切圖片檔
         * @param { type String(字串) } image 圖片 base64
         */
        async coverPhotodData(file) {
            this.coverLoading = true;
            try {
                const result = new FormData();
                result.append("image", file);
                const { data } = await this.$api.UploadCoverApi(this.$route.params.id, result);
                this.coverLoading = false;
                this.$refs.coverPhotoUpload.setCropped(data.cover);
                this.$message({
                    type: "success",
                    message: "上傳封面照成功",
                });
            } catch (err) {
                this.coverLoading = false;
                this.$refs.coverPhotoUpload.handleRemove();
                this.$message({
                    type: "error",
                    message: "上傳封面照失敗",
                });
            }
        },
        /**
         * 裁切後圖檔
         * @param { type Blob(檔案)) } file 裁切後檔案
         * @param { type Array(陣列) } image 裁切後 base64圖片
         */
        async otherPhotoData(file) {
            this.loading = true;
            try {
                const result = new FormData();
                result.append("image", file);
                const { data } = await this.$api.UploadOtherPhotoApi(this.$route.params.id, result);
                this.loading = false;
                this.imageList.push(data.photo);
                this.$message({
                    type: "success",
                    message: "上傳其他照片成功",
                });
            } catch (err) {
                this.loading = false;
                // this.imageList.splice(index, 1);
                // this.$refs.mutipleUpload.removeFileByIndex(index);
                this.$message({
                    type: "error",
                    message: "上傳其他照片失敗",
                });
            }
        },
        /**
         * 刪除其他照片
         * 列表索引
         */
        async deleteOtherPhoto(index, id) {
            try {
                await this.$confirm("您確定刪除此照片嗎?", "提示", {
                    confirmButtonText: "確定",
                    cancelButtonText: "取消",
                    type: "warning",
                    customClass: "top-1/2 absolute z-20 md:w-[500px] w-full md:left-1/2 left-0",
                });
                try {
                    this.loading = true;
                    const { data } = await this.$api.DeleteOtherPhotoApi(this.$route.params.id, id);
                    console.log(data);
                    this.loading = false;
                    this.imageList.splice(index, 1);
                    this.$message({
                        type: "success",
                        message: "刪除成功",
                    });
                } catch (err) {
                    this.loading = false;
                    this.$message({ type: "error", message: "刪除失敗" });
                }
            } catch (err) {
                console.log(err);
            }
        },
        // 刪除封面照
        async deleteCoverPhoto() {
            try {
                await this.$confirm("您確定刪除此封面照片嗎?", "提示", {
                    confirmButtonText: "確定",
                    cancelButtonText: "取消",
                    type: "warning",
                    customClass: "top-1/2 absolute z-20 md:w-[500px] w-full md:left-1/2 left-0",
                });
                try {
                    this.coverLoading = true;
                    const { data } = await this.$api.DeleteCoverApi(this.$route.params.id);
                    console.log(data);
                    this.coverLoading = false;
                    this.$refs.coverPhotoUpload.handleRemove();
                    this.$message({
                        type: "success",
                        message: "刪除成功",
                    });
                } catch (err) {
                    this.coverLoading = false;
                    this.$message({
                        type: "error",
                        message: "刪除失敗",
                    });
                }
            } catch (err) {
                console.log(err);
            }
        },
        // 設定表單值
        setDefault(val) {
            if (val === undefined) {
                return;
            }
            // 判斷使用者是否有封面照 如果沒有封面照 則不給值
            if (!this.$isEmpty(val.cover)) {
                this.coverPhoto = val.cover;
            }
            // 判斷使用者是否有其他照片照 如果沒有封面照 則不給值
            if (!this.$isEmpty(val.photos)) {
                this.imageList = val.photos;
            }
        },
        onSubmit() {
            // 判斷是否有上傳封面照
            if (this.coverPhoto === null) {
                // 沒有上傳封面照時回傳失敗給父組件
                this.$emit("onAboutFormValidate", false);
            } else {
                // 上傳封面照成功時回傳成功給父組件
                this.$emit("onAboutFormValidate", true);
            }
        },
    },
    watch: {
        providerData(val) {
            this.imageList = [];
            this.setDefault(val);
        },
    },
    created() {
        this.setDefault(this.providerData);
    },
};
</script>

<style lang="scss">
// .el-message-box__wrapper {
//     position: fixed #{!important};
//     top: 50% #{!important};
// }
</style>
